const regexToGetAllInsideStyleTag = /<style\b[^>]*>([\s\S]*?)<\/style>/gm;
const regexToRemoveAllSpaceOrLineBreak = /^\s+|\r\n|\n|\r|(>)\s+(<)|<!--.*?-->|\s+$/gm; 
const regexToGetAllInsideScriptTag = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
const regexToGetAllInsideNoScriptTag = /<noscript\b[^>]*>([\s\S]*?)<\/noscript>/gm;

const stringMinify = (value:string|null) => {
    if (!value) return value as string;
    return value.trim().replace(regexToRemoveAllSpaceOrLineBreak, '$1$2');
};

const extractStyle = (value:string|null) => {
    if (!value) return value as string;
    return (value.match(regexToGetAllInsideStyleTag))?.toString().trim().replace(/<style>|<\/style>/g, '') || null; 
};

const removeStyleTag = (value:string|null) => {
    if (!value) return value as string;
    return value.replace(regexToGetAllInsideStyleTag, '');
};

const extractScript = (value:string|null) => {
    if (!value) return value as string;
    return (value.match(regexToGetAllInsideScriptTag))?.toString().trim().replace(/<script>|<\/script>/g, '') || null;
};

const extractNoScript = (value:string|null) => {
    if (!value) return value as string;
    return (value.match(regexToGetAllInsideNoScriptTag))?.toString().trim().replace(/<noscript>|<\/noscript>/g, '') || null;
};

const removeScriptTag = (value:string|null) => {
    if (!value) return value as string;
    return value.replace(regexToGetAllInsideScriptTag, '');
};

const replaceStyleTag = (value: string|null) =>{
    return value?.trim().replace(/<style>|<\/style>/gm, '') || null;
};

const replaceLinkTag = (value: string|null) =>{
    return value?.trim().replace(/<link>|<\/link>/gm, '') || null;
};

const replaceScriptTag = (value: string|null) =>{
    return value?.trim().replace(/<script>|<\/script>/gm, '') || null;
};

const newGuid = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export {
    stringMinify,
    extractStyle,
    removeStyleTag,
    extractScript,
    extractNoScript,
    removeScriptTag,
    replaceStyleTag,
    replaceLinkTag,
    replaceScriptTag,
    newGuid,
};
