import map from 'lodash.map';
import findIndex from 'lodash.findindex';
import { isEmpty } from 'utils/commonUtils';

const getFieldsMeta = (schema: any) => {
    const fieldsInformation: any = {};
    if (!isEmpty(schema)) {
        const fields = schema.type === 'object' ? schema.fields : schema.innerType.fields;
        const keyFields = Object.keys(fields);

        keyFields.forEach((item) => {
            fieldsInformation[item] = {
                isRequired: fields[item]?.exclusiveTests?.required || false,
            };
        });
    }

    return fieldsInformation;
};

const isValidSchema = (schema: any, record: any) => {
    try {
        schema.validateSync(record, { abortEarly: false, strict: true });
        return {
            isValid: true,
            errors: [],
            fields: getFieldsMeta(schema),
        };
    } catch (err: any) {
        const mapError = map(err?.inner || [], (item) => ({
            field: item.path,
            message: item.message,
        }));

        return {
            isValid: (err?.inner || []).length === 0,
            errors: mapError,
            fields: getFieldsMeta(schema),
        };
    }
};

const isInvalidField = (field: any, errors: any = []) => {
    const index = findIndex(errors, { field });
    return index > -1;
};

const getErrorMessage = (field: any, errors: any = []) => {
    return errors.find((c: any) => c.field === field)?.message || '';
};

export {
    isValidSchema,
    isInvalidField,
    getErrorMessage,
};
