interface ICloudinaryLoaderProps {
    src: string,
    width?: number,
    quality?: number,
    shouldResizeImg?: boolean
}

const path = 'https://dealer-websites.s3.us-east-2.amazonaws.com/website-resources/';

const cloudinaryLoader = ({ src, width, quality }:ICloudinaryLoaderProps) => {   
    if (!src) return src; 
    const newSRC = cloudinaryApply(src);
    return `${newSRC}?tx=w_${width},q_${quality || 100}`;
};

const cloudinaryApply = (string:string|null) => {
    if (!string) return string as string;
    const cloudinaryPath = 'https://automatrix-cms.mo.cloudinary.net/content/';
    return string.includes(path) ? string.replace(new RegExp(path, 'g'), cloudinaryPath) : string;
};

const cloudinaryLoaderVehicleImages = ({ src, shouldResizeImg }:ICloudinaryLoaderProps) => {   
    if (!src) return src; 
    return replacePathAWS(src, 450, shouldResizeImg);
};
const cloudinaryLoaderVDPImages = ({ src, shouldResizeImg }:ICloudinaryLoaderProps) => {  
    if (!src) return src; 
    return replacePathAWS(src, 1024, shouldResizeImg);
};
const cloudinaryLoaderSimilar = ({ src, shouldResizeImg }:ICloudinaryLoaderProps) => {  
    if (!src) return src; 
    return replacePathAWS(src, 450, shouldResizeImg);
};

const cloudinaryLoaderVDPImagesMobile = ({ src, shouldResizeImg }:ICloudinaryLoaderProps) => {  
    if (!src) return src; 
    return replacePathAWS(src, 800, shouldResizeImg);
};

const cloudinaryLoaderVDPThumbnailImages = ({ src, shouldResizeImg }:ICloudinaryLoaderProps) => {  
    if (!src) return src; 
    return replacePathAWS(src, 100, shouldResizeImg);
};

const defaultLoader = ({ src }:ICloudinaryLoaderProps) => {   
    return replacePathAWS(src); 
};

const cloudinaryLoaderImage = ({ src }:ICloudinaryLoaderProps) => {  
    return replacePathAWS(src);
};

const replacePathAWS = (url: string, width = 450, shouldResizeImg = false) => {
    if (url.includes('/common-resources/')) {
        return forceHttps(url);
    }
    if (shouldResizeImg) {
        return forceHttps(url).replace(/\.[^/.]+$/, `-w${width}.`) + url.split('.').pop();
    }
    return forceHttps(url);
};

const forceHttps = (url: string) => url?.replace('http://', 'https://'); 

export {
    cloudinaryLoader,
    cloudinaryApply,
    cloudinaryLoaderVehicleImages,
    cloudinaryLoaderVDPImages,
    cloudinaryLoaderVDPThumbnailImages,
    defaultLoader,
    cloudinaryLoaderImage,
    cloudinaryLoaderVDPImagesMobile,
    cloudinaryLoaderSimilar,
};
