import { InputHTMLAttributes, forwardRef } from 'react';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';
import { isInvalidField, isValidSchema, getErrorMessage } from 'utils/SchemaUtils';

interface InputProps extends InputHTMLAttributes<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
    name: string;
    className: string;
    ref: string;
    formSchema: any;
    record: object;
    onChange: (event:React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
}

const InputField = forwardRef<HTMLInputElement, InputProps>(({
    formSchema,
    record,
    className,
    name,
    onChange,
    ...restProps
}, ref) => {
    const isValidData = isValidSchema(formSchema, record);
    const { errors } = isValidData;

    return (
        <>
            <input name={name} onChange={onChange} className={classNames(`${className}`, isInvalidField(name, errors) ? 'invalid-field' : '')} {...restProps} ref={ref} />
            <ErrorMessage message={getErrorMessage(name, errors)} />
        </>
    );
});

InputField.displayName = 'InputField';


const DropdownField = forwardRef<HTMLSelectElement, InputProps>(({
    formSchema,
    record,
    className,
    name,
    onChange,
    children,
    ...restProps
}, ref) => {
    const isValidData = isValidSchema(formSchema, record);
    const { errors } = isValidData;

    return (
        <>
            <select name={name} onChange={onChange} className={classNames(`${className}`, isInvalidField(name, errors) ? 'invalid-field' : '')} {...restProps} ref={ref}>
                {children}
            </select>
            <ErrorMessage message={getErrorMessage(name, errors)} />
        </>
    );
});

DropdownField.displayName = 'DropdownField';

const TextAreaField = forwardRef<HTMLTextAreaElement, InputProps>(({
    formSchema,
    record,
    className,
    name,
    onChange,
    ...restProps
}, ref) => {
    const isValidData = isValidSchema(formSchema, record);
    const { errors } = isValidData;

    return (
        <>
            <textarea name={name} onChange={onChange} className={classNames(`${className}`, isInvalidField(name, errors) ? 'invalid-field' : '')} {...restProps} ref={ref} />
            <ErrorMessage message={getErrorMessage(name, errors)} />
        </>
    );
});

TextAreaField.displayName = 'TextAreaField';

export { InputField, DropdownField, TextAreaField };