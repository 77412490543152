import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import useOtherMethods from "components/Hooks/useOtherMethods";


const Captcha = ({ onValidateCaptcha } : any) => {
    const {verifyRecaptcha} = useOtherMethods();

    const onReCAPTCHAChange = async (captchaCode: any) => {
        if(!captchaCode) {
            onValidateCaptcha(false);
            return;
        }

        const verifyToken = await verifyRecaptcha(captchaCode);

        if(!verifyToken) onValidateCaptcha(false); else onValidateCaptcha(true);
    };

    return(
        <ReCAPTCHA
            size="normal"
            type="image"
            badge="bottomright"
            sitekey={String(process.env.RECAPTHA_PUBLIC_KEY)}
            onChange={onReCAPTCHAChange}
        />
    );
};

export default Captcha;