const filterData = (data: any, filterItem: any) => {
    const rangeProperties = ['internetprice', 'miles', 'year'];

    const {filterName} = filterItem;
    const filteredData = filterItem.conditions && filterItem.conditions.length > 0 ? 
        data.filter((item: any) => {
            const resultCondition = filterItem.conditions.map((condition: any) => {
                const {label, type, values } = condition;

                if((values && values.length === 0) || !values) return true;
                if(type === 'array') return values.includes(item[label]);
                if(condition.type === 'range') {
                    if(condition.label === 'miles') return Number(item[label]) <= Number(values[1]);
                    else return Number(item[label]) >= Number(values[0]) && Number(item[label]) <= Number(values[1]);
                }
            });

            return resultCondition.every((v: boolean) => v === true);
        }) : data;

    if(rangeProperties.includes(filterName.toLowerCase()))
    {
        const minandmax = getMinMaxFromArray(filteredData.map((c: any)=> c[filterName]), filterName);

        return [{ 
            keyName: minandmax, 
            typeName: filterName,
            countValue: 1,
            sortNumber: 1,
            isSelected: false,
        }]; 
    }

    const occurences = filteredData.sort((a: any, b: any)=> a[filterItem.filterName].localeCompare(b[filterItem.filterName])).reduce((r:any, row: any) => {
        r[row[filterName]] = ++r[row[filterName]] || 1;
        return r;
    }, {});
    
    const result = Object.keys(occurences).map((key) => {
        return { keyName: key, 
            typeName: filterName,
            countValue: occurences[key],
            sortNumber: 1,
            isSelected: filterItem.values.includes(key),
        };
    });

    return result as [];
};

const pushDataToArray = (first: any[], second: any[]) => {
    Array.prototype.push.apply(first, second);
};

const getMinMaxFromArray = (array: any[], filterName: string) =>{
    const min = filterName === 'miles' ? 0 : Math.min.apply(null, array) || 0;
    const max = Math.max.apply(null, array) || 0;
    let divider = 10;
    let increase = 20;

    switch (filterName.toLowerCase()) {
    case "internetprice":
        divider = 100; increase = 100;
        break;
    case "miles":
        divider = 1000; increase = 1000;
        break;
    case "year":
        divider = 1; increase = 0;
        break;    
    default:
        break;
    }

    const minSuggested = Math.round(min / divider) * divider;
    const newMin = filterName === 'miles' ? 0 : (minSuggested < min ? minSuggested : minSuggested - increase);

    const maxSuggested = Math.round(max / divider) * divider;
    const newMax = maxSuggested > max ? maxSuggested : maxSuggested + increase;

    return `${newMin}|${newMax}`;
};


const getFiltersInArray = (filtersFromParams: any) => {
    const filtersFromUI = [];

    // aqui las condiciones que estan en un objeto las meto en un arreglo
    filtersFromUI.push(filtersFromParams.internetPrice);
    filtersFromUI.push(filtersFromParams.miles);
    filtersFromUI.push(filtersFromParams.year);
    filtersFromUI.push(filtersFromParams.style);
    filtersFromUI.push(filtersFromParams.make);
    filtersFromUI.push(filtersFromParams.model);
    filtersFromUI.push(filtersFromParams.transmission);
    filtersFromUI.push(filtersFromParams.lotName);

    return filtersFromUI;
};

const groupByOneColumn = (data: [], column1: string) => {
    return data.reduce((acc: any, obj: any) => {
        const existingIndex = acc.findIndex(
            (el: any) => el[column1] === obj[column1]
        );
        if (existingIndex > -1) {
            acc[existingIndex].count += 1;
        } else {
            acc.push({
                [column1]: obj[column1],
                column1: obj[column1],
                columnType: column1,
                count: 1
            });
        }
        return acc;
    }, []);
};

const groupByTwoColumn =  (data: [], column1: any, column2: any) =>{
    return data.reduce((acc: any, obj: any) => {
        const existingIndex = acc.findIndex(
            (el: any) => el[column1] === obj[column1] && el[column2] === obj[column2]
        );
        if (existingIndex > -1) {
            acc[existingIndex].count += 1;
        } else {
            acc.push({
                [column1]: obj[column1],
                [column2]: obj[column2],
                column1: obj[column1],
                column2: obj[column2],
                count: 1
            });
        }
        return acc;
    }, []);
};

const groupByThreeColumn =  (data: [], column1: string, column2: string, column3: string) =>{
    return data.reduce((acc: any, obj:any ) => {
        const existingIndex = acc.findIndex((el: any) => el[column1] === obj[column1] && el[column2] === obj[column2] && el[column3] === obj[column3]);
        if (existingIndex > -1) {
            acc[existingIndex].count += 1;
        } else {
            acc.push({
                [column1]: obj[column1],
                [column2]: obj[column2],
                [column3]: obj[column3],
                column1: obj[column1],
                column2: obj[column2],
                column3: obj[column3],
                count: 1
            });
        }
        return acc;
    }, []);
};

const groupByFourColumn =  (data: [], column1: string, column2: string, column3: string, column4: string) =>{
    return data.reduce((acc: any, obj:any ) => {
        const existingIndex = acc.findIndex((el: any) => el[column1] === obj[column1] && el[column2] === obj[column2] && el[column3] === obj[column3] && el[column4] === obj[column4]);
        if (existingIndex > -1) {
            acc[existingIndex].count += 1;
        } else {
            acc.push({
                [column1]: obj[column1],
                [column2]: obj[column2],
                [column3]: obj[column3],
                [column4]: obj[column4],
                count: 1
            });
        }
        return acc;
    }, []);
};

export {
    filterData,
    pushDataToArray,
    getMinMaxFromArray,
    getFiltersInArray,
    groupByOneColumn,
    groupByTwoColumn,
    groupByThreeColumn,
    groupByFourColumn,
};