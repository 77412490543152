import { FiltersDB } from 'models/filtersDB';
import { VehicleResponse } from 'models/vehicleResponse';
import { createContext } from 'react';

export type VehicleContextInterface = {
    settings: any,
    paramsFromUrl: any,
    currentState: any,
    originalFilterData: any,
    vehicleResponse: VehicleResponse,
    resetFiltersClearAll: () => void,
    setLoadingVehicles: (val: boolean) => void,
    setLoadingFilters: (val: boolean) => void,
    onSetDropdownOpen: (filterName:string, val: boolean) => void,
    setFilter: (filterName:string, val: string[]) => void,
    resetFiltersDueToSearch: () => void,
    setSelectedItemInDropdown: (filterName:string, val: any) => void,
    setSelectedItemInSlider: (filterName:string, val: any, previousData: FiltersDB, valueReseted: boolean) => void,
    setStateValues: (val: any) => void,
    currentLocation: string,
    filtersFromUrl: any,
};

const defaultContextValues: VehicleContextInterface = {
    settings: {},
    paramsFromUrl: {},
    currentState: {},
    originalFilterData: [],
    vehicleResponse: {
        getWebsiteVehicleList: {},
        total: 0,
        totalSearch: 0,
        page: 0,
        size: 0,
        cleanCache: false,
        data: [],
        posibleMatchList: [],
        hash: ''
    },
    resetFiltersClearAll: () => null,
    setLoadingVehicles: () => null,
    setLoadingFilters: () => null,
    onSetDropdownOpen: () => null,
    setFilter: () => null,
    resetFiltersDueToSearch: () => null,
    setSelectedItemInDropdown: () => null,
    setSelectedItemInSlider: () => null,
    setStateValues: () => null,
    currentLocation: '',
    filtersFromUrl: {},
};
  
const VehicleContext = createContext<VehicleContextInterface>(defaultContextValues);

export { VehicleContext };
